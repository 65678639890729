import orderBy from 'lodash/orderBy';
import { computed, ref } from 'vue';

import { MarketType } from '@exchange/libs/market/service/src/lib/market-model';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { FeeGroupId, type BEFeesRunningVolume, PublicFeeGroup, FeeTier, type BEFeeTier, FeeType, type BEPublicFeeGroup } from './fee-tiers-model';

class Fees {
  public publicFees = ref<Record<FeeGroupId, PublicFeeGroup> | undefined>();

  public accountFeeCurrencies = ref<Record<FeeGroupId, string> | undefined>();

  public accountTradingVolumes = ref<Record<FeeGroupId, number> | undefined>();

  public accountCurrentFees = ref<Record<FeeGroupId, FeeTier | undefined> | undefined>();

  public accountCurrentFeeTierIndexes = computed(() => {
    if (!this.accountCurrentFees.value) {
      return undefined;
    }

    return Object.entries(this.accountCurrentFees.value).reduce(
      (acc, [feeGroupId, feeTier]) => {
        const index = this.publicFees.value?.[feeGroupId]?.feeTiers.findIndex((publicFeeTier) => feeTier?.volume === publicFeeTier.volume) ?? -1;

        acc[feeGroupId] = index === -1 ? undefined : index + 1;

        return acc;
      },
      {} as Record<FeeGroupId, number | undefined>,
    );
  });

  public publicCurrentFeeTiers = computed<Record<FeeGroupId, FeeTier | undefined> | undefined>(() => {
    if (!this.publicFees.value) {
      return undefined;
    }

    return Object.entries(this.publicFees.value).reduce(
      (acc, [feeGroupId, feeGroup]) => {
        const [currentFeeTier] = orderBy(feeGroup.feeTiers, FeeType.taker, 'desc');

        acc[feeGroupId] = currentFeeTier;

        return acc;
      },
      {} as Record<FeeGroupId, FeeTier | undefined>,
    );
  });

  private setAccountFees = (v: BEFeeTier[]) => {
    this.accountCurrentFees.value = v.reduce(
      (acc, curr) => {
        acc[curr.fee_group_id] = new FeeTier(curr);
        return acc;
      },
      {} as Record<FeeGroupId, FeeTier>,
    );
  };

  private setAccountFeeCurrencies = (runningVolumes: BEFeesRunningVolume[]) => {
    this.accountFeeCurrencies.value = runningVolumes.reduce(
      (acc, curr) => {
        acc[curr.fee_group_id] = curr.currency;
        return acc;
      },
      {} as Record<FeeGroupId, string>,
    );
  };

  private setAccountTradingVolumes = (runningVolumes: BEFeesRunningVolume[]) => {
    this.accountTradingVolumes.value = runningVolumes.reduce(
      (acc, curr) => {
        acc[curr.fee_group_id] = curr.volume;
        return acc;
      },
      {} as Record<FeeGroupId, number>,
    );
  };

  private setPublicFees = (v: BEPublicFeeGroup[]) => {
    this.publicFees.value = v.reduce(
      (acc, curr) => {
        acc[curr.fee_group_id] = new PublicFeeGroup(curr);
        return acc;
      },
      {} as Record<FeeGroupId, PublicFeeGroup>,
    );
  };

  public fetchAccountFees = async () => {
    try {
      const accountFees = await PublicRest.Account.Fees.get();

      this.setAccountFees(accountFees.active_fee_tiers);
      this.setAccountFeeCurrencies(accountFees.running_volumes);
      this.setAccountTradingVolumes(accountFees.running_volumes);

      // return Boolean(feeGroup.user_is_blocked); // TODO: Need to get this from somewhere else

      return false;
    } catch (error) {
      logger.warn('Fetching account fees failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      return this.fetchAccountFees();
    }
  };

  public fetchPublicFees = async () => {
    try {
      const feeGroups = await PublicRest.Fees.get();

      this.setPublicFees(feeGroups);
    } catch (error) {
      logger.warn('Fetching public fees failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      await this.fetchPublicFees();
    }
  };

  public getFeeGroupIdForMarketType = (marketType: MarketType) => {
    switch (marketType) {
      case MarketType.SPOT:
        return FeeGroupId.SPOT;
      case MarketType.PERP:
        return FeeGroupId.FUTURES;
      default:
        throw new Error(`Unknown market type: ${marketType}`);
    }
  };
}

const feesService = new Fees();

export default feesService;
