// TODO: should this be a ProductType enum?
export enum FeeGroupId {
  SPOT = 'SPOT',
  FUTURES = 'FUTURES',
}

export enum FeeType {
  maker = 'maker',
  taker = 'taker',
}

export interface BEFeeTier {
  fee_group_id: FeeGroupId;
  maker_fee: string;
  taker_fee: string;
  volume: string;
}

export interface BEFeesRunningVolume {
  fee_group_id: FeeGroupId;
  currency: string;
  volume: number;
}

export interface BEAccountFees {
  account_id: string;
  active_fee_tiers: BEFeeTier[];
  running_volumes: BEFeesRunningVolume[];
}

export interface BEPublicFeeGroup {
  display_text: string;
  fee_group_id: FeeGroupId;
  fee_tiers: BEFeeTier[];
  volume_currency: string;
}

export interface FeeTierFees {
  [FeeType.maker]: number;
  [FeeType.taker]: number;
}

export class FeeTier {
  public fees!: FeeTierFees;

  public id!: FeeGroupId;

  public volume!: number;

  constructor(feeTier: BEFeeTier) {
    this.id = feeTier.fee_group_id;
    this.fees = {
      [FeeType.maker]: Number(feeTier.maker_fee),
      [FeeType.taker]: Number(feeTier.taker_fee),
    };
    this.volume = Number(feeTier.volume);
  }
}

export class PublicFeeGroup {
  public feeTiers!: FeeTier[];

  public id!: FeeGroupId;

  constructor(feeGroup: BEPublicFeeGroup) {
    this.id = feeGroup.fee_group_id;
    this.feeTiers = feeGroup.fee_tiers.map((feeTier) => new FeeTier(feeTier));
  }
}
