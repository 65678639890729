import { computed, reactive, ref } from 'vue';

import { resetCache, withCache, type WithCacheState } from '@exchange/helpers/with-cache';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

type FeatureRestrictionsServiceState = WithCacheState<{
  blocked: boolean | null;
  restrictions: string[] | null;
}>;

const CACHE_TTL_MS = 1000 * 60 * 5; // 5 minutes

const getDefaultState = (): FeatureRestrictionsServiceState => ({
  cache: null,
  cacheTimestamp: null,
});

class FeatureRestrictionsService {
  private readonly state = reactive<FeatureRestrictionsServiceState>(getDefaultState());

  public isBlocked = computed(() => this.state.cache?.blocked ?? false);

  public restrictions = computed(() => this.state.cache?.restrictions ?? []);

  public isFetchedRestrictions = computed(() => this.state.cache !== getDefaultState().cache);

  public isLoadingRestrictions = ref(false);

  public get = withCache(
    async (): Promise<FeatureRestrictionsServiceState['cache']> => {
      if (this.isLoadingRestrictions.value) {
        return this.state.cache;
      }

      this.isLoadingRestrictions.value = true;

      try {
        const data = await PublicRest.FeatureRestrictions.get();

        return data;
      } catch (error) {
        logger.error('Fetching feature restrictions failed; retrying later', error);
        await retryService.waitForNextRetryTick();
        return await this.get();
      } finally {
        this.isLoadingRestrictions.value = false;
      }
    },
    this.state,
    { ttlMs: CACHE_TTL_MS },
  );

  public reset = () => {
    resetCache(this.state, getDefaultState());
  };
}

const featureRestrictionsService = new FeatureRestrictionsService();

export default featureRestrictionsService;
